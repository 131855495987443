import React, {useEffect, useRef, useState} from "react";
import "./LoginPage.css";
import "../../pageComponents/headerFooter/HeaderSection.css";
import Header from "../../pageComponents/headerFooter/Header";
import Footer from "../../pageComponents/headerFooter/Footer";
import {useLocation, useNavigate } from "react-router-dom";
import Loader from "../../loader/Loader";

import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { YupLogin } from "./yupLogin/YupLogin";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, addUser } from "../../reduxStore/slices/GetUserSlice";
import jwtDecode from "jwt-decode";

import Cookies from "universal-cookie";
import BackHomeBtn from "../../pageComponents/backHomeBtn/backHomeBtn";
const LoginPage = () => {

    const [error, setError] = useState(null);
    const [isLogin, setIsLogin] = useState(null);
    const [isLoginTransit, setLoginTransit] = useState(null);
    const { hash } = useLocation();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    axios.defaults.withCredentials = true;
    const disclaimerRef = useRef(null); // Create a ref for the disclaimer message
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);


    useEffect(() => {
        try {
            // if (isLogin) {
            const usatCookie = cookies.get("usat");
            if(!usatCookie){
            }else{
                dispatch(addUser(jwtDecode(usatCookie)));
                navigate(user ? `/cabinet/${user.username}/home` : '/login');
                // window.location.reload(); // todo to be optimised later
            }
            // }
        } catch (e) {
            console.error(e);
        }
    }, [isLogin]);
    // Show the disclaimer after the component is mounted


    const handleClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        // Optionally, show the message after a certain time or based on other conditions
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 2000); // Message will appear after 2 seconds

        return () => clearTimeout(timer); // Clean up the timer
    }, []);
    useEffect(() => {
        if (user !== null) {
            navigate(user ? `/cabinet/${user.username}/home` : '/login');
        }
    }, [navigate, user]);




    const {
        register,
        handleSubmit,
        formState: { errors, notValid },
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(YupLogin),
        defaultValues: {
            username: "",
            password: "",
        },
    });

    const onSubmit = handleSubmit((data) => {
        setError(null); // Clear previous errors
        setLoginTransit(true); // Show loading state
        try {
            axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/login`, {
                username: data.username,
                password: data.password,
            })
                .then((res) => {
                    setLoginTransit(false); // Hide loading state
                    setIsLogin(res.data.message); // Set success message from response
                    if (res.status === 200){
                        cookies.remove("usat");
                        // await cookies.set("usat", res.data.token);
                       cookies.set('usat', res.data.token, {
                            path: '/',
                            secure: true,             // Only send the cookie over HTTPS
                            maxAge: 60 * 60 * 24 * 30,  // 30 days
                        });
                        cookies.set('sbVersion', res.data.sbVersion, {
                            path: '/',
                            secure: true,             // Only send the cookie over HTTPS
                            maxAge: 60 * 60 * 24 * 30,  // 30 days
                        });
                        navigate(user ? `/cabinet/${user.username}/home` : '/login');
                    }
                })
                .catch((error) => {
                    setLoginTransit(false); // Hide loading state in case of error

                    // Check if error.response exists and contains data
                    if (error.response && error.response.data) {
                        setError(error.response.data.error); // Set error message from response
                    } else {
                        setError('An unknown error occurred.'); // Fallback message if no response is available
                    }
                });
        } catch (e) {
            console.error(e);
            setLoginTransit(false); // Hide loading state if there's an exception
            setError('An error occurred while processing the request.');
        }
    });
    return !isLoginTransit ? (
        <div className="login-page">
            <Header titleName="Smart Bird Cabinet"/>
            <form className="login-form" autoComplete="off" onSubmit={onSubmit}>
                <div>
                    <h2>Login</h2>
                </div>

                {error && <p className="error-message">{error}</p>}
                <div className="user-box">
                    <input
                        {...register("username")}
                        name="username"
                        type="text"
                        required
                    />
                    <label>Username or email</label>
                    {errors?.username && (
                        <p className="error-message">{errors?.username?.message}</p>
                    )}
                </div>
                <div className="user-box">
                    <input
                        {...register("password")}
                        name="password"
                        type="password"
                        required
                    />
                    <label>Password</label>
                    {errors?.password && (
                        <p style={{color: "red"}}>{errors?.password?.message}</p>
                    )}
                </div>

                <button
                    className="submit-btn"
                    type="submit"
                    disabled={notValid} // Disable if reCAPTCHA is not validated or form is invalid
                >
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    Submit
                </button>
                <div className="forgot-password-link">
                    <a href="/forgot-password">Forgot password?</a>
                </div>

            </form>
            <div className={`disclaimer-message ${isVisible ? 'show' : ''}`}>
            <p className={"disclaimer-message-text"}>
                    You can log in only if you have already received a service from us and we have provided you with account details for your home setup. If you haven't received this information, please <a style={{color: "#FFF", textDecoration: "underline"}} href="/#contact">contact us</a>.
                </p>
                <button className="close-btn" onClick={handleClose}>X</button>
            </div>
            <BackHomeBtn/>
            <Footer/>
        </div>
    ): (
        <Loader />
    );
};

export default LoginPage;
