import React, { useState, useEffect } from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../loader/Loader";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import "./UserStatements.css";
import {jsPDF} from "jspdf";

const PURCHASES_PER_PAGE = 24;

const UserStatements = () => {
    const user = useSelector((state) => state.user.user);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPagePurchases, setCurrentPagePurchases] = useState(1);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentHistory = async () => {
            setLoading(true);

            try {
                const cachedData = JSON.parse(localStorage.getItem("paymentHistory"));
                const cacheTimestamp = localStorage.getItem("paymentHistoryTimestamp");
                const now = new Date().getTime();

                if (cachedData && cacheTimestamp && now - cacheTimestamp < 10 * 60 * 1000) {
                    setPaymentHistory(cachedData);
                    setLoading(false);
                    return;
                }

                if (cookies.get("usat")) {
                    const usat = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("usat="))
                        ?.split("=")[1];
                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER_DOMAIN}/payment-history`,
                        {
                            userId: user?.id || "Id Not set",
                            username: user?.username || "User Not set",
                            userEmail: user?.email || "Email Not set",
                        },
                        {
                            headers: {
                                Authorization: `${usat}`,
                            },
                            withCredentials: true,
                        }
                    );

                    const paymentHistoryData = response.data.data;
                    if (Array.isArray(paymentHistoryData)) {
                        setPaymentHistory(paymentHistoryData);
                        localStorage.setItem("paymentHistory", JSON.stringify(paymentHistoryData));
                        localStorage.setItem("paymentHistoryTimestamp", new Date().getTime());
                    } else {
                        console.error("Unexpected data format:", paymentHistoryData);
                        setPaymentHistory([]);
                    }
                } else {
                    dispatch(addUser(null));
                    dispatch(setLogin(false));
                    cookies.remove("usat");
                    cookies.remove("sbVersion");
                    navigate(`/login`);
                }
            } catch (error) {
                console.error("Error fetching payment history:", error);
                setError("Error fetching payment history");
                setPaymentHistory([]);
            } finally {
                setLoading(false);
            }
        };

        if (user?.id) {
            fetchPaymentHistory();
        }

        const intervalId = setInterval(() => {
            fetchPaymentHistory();
        }, 10 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [user]);

    const totalPurchasePages = Math.ceil(paymentHistory.length / PURCHASES_PER_PAGE);
    const currentPurchases = paymentHistory.slice(
        (currentPagePurchases - 1) * PURCHASES_PER_PAGE,
        currentPagePurchases * PURCHASES_PER_PAGE
    );

    const handlePurchasePageChange = (page) => setCurrentPagePurchases(page);


    const generatePDF = (successResponse) => {
        const doc = new jsPDF();
        doc.text("Receipt", 105, 20, null, null, "center");
        // Parse the userPurchaseTime string to create a Date object
        const userPurchaseTime = new Date(successResponse?.userPurchaseTime);
        const formattedDate = `${(userPurchaseTime.getMonth() + 1).toString().padStart(2, '0')}/${userPurchaseTime.getDate().toString().padStart(2, '0')}/${userPurchaseTime.getFullYear()}`;
        const formattedTime = `${userPurchaseTime.getHours().toString().padStart(2, '0')}:${userPurchaseTime.getMinutes().toString().padStart(2, '0')}:${userPurchaseTime.getSeconds().toString().padStart(2, '0')}`;

        doc.text(`Date: ${formattedDate}`, 105, 30, null, null, "center");

        doc.text("Company Name: Smart Bird Solution", 20, 50);
        doc.text("Address: 123 Smart St, Smart City", 20, 60);
        doc.text("Return Policy: Varies by product type.", 20, 70);
        doc.text("If you have questions about charges, email: smartbirdservice@gmail.com", 20, 80);
        doc.text("Payment was processed securely via PayPal.", 20, 90);
        doc.text("You can find your PayPal receipt in your PayPal account.", 20, 100);

        doc.text(`Paypal Invoice Number: ${successResponse?.invoiceOrderNumber}`, 20, 120);

        doc.text("Sale Transaction", 20, 130);
        doc.text(`Purchase Type: ${successResponse?.productType}`, 20, 140);
        doc.text(`Payment Id: ${successResponse?.subId}`, 20, 150);
        doc.text(`Status: ${successResponse?.subStatus}`, 20, 160);
        doc.text(`Price: $ ${successResponse?.purchaseValue}`, 20, 170);
        doc.text("Tax: Included", 20, 180);
        doc.text(`Total Price: $ ${successResponse?.purchaseValue}`, 20, 190);

        doc.text("Guest Copy", 105, 220, null, null, "center");
        doc.text("Thank you!", 105, 230, null, null, "center");
        doc.text(`Date & Time: ${formattedDate} ${formattedTime}`, 105, 240, null, null, "center");

        doc.setTextColor(0, 0, 265); // Set text color to blue (RGB)
        doc.textWithLink('Privacy Policy', 20, 260, {url: 'https://www.smartbirdsolution.com/privacy-policy/'});
        doc.textWithLink('Terms Of Service', 20, 270, {url: 'https://www.smartbirdsolution.com/terms-of-service/'});

        doc.save("Receipt.pdf");
    };

    const handleDownloadReceipt = async (user, paymentId) => {
        setLoading(true);
        const orderData = {
            username: user.username,
            purchaseId: paymentId,
        };
        if (cookies.get("usat")) {
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {
                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/user-receipt-request`,
                    orderData,
                    {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    },
                    {timeout: 5000});
                if (res.status === 200) {
                    console.log("received receipt data");
                    await generatePDF(res.data.receiptData);
                } else {
                    console.log("Unexpected status:", res.status);
                    // window.location.href = res.data;
                    // alert("Unexpected error! Operation wasn't successful. Please contact us.");
                    // logoutToLoginPage();
                }

            } catch (e) {
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    // if (status === 401) {
                    //     console.log("Authorization error");
                    //     logoutToLoginPage();
                    // } else if (status === 404) {
                    //     console.log("User not found");
                    //     logoutToLoginPage();
                    // } else if (status === 500) {
                    //     console.log("Failed to create payment");
                    // } else {
                    //     console.log("Unhandled error:", status);
                    // }
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                }
            }
        }
        setLoading(false);

    };

    return loading ? (
        <Loader />
    ) : (
        <section className="user-billings-content">
            <h2>Purchase History</h2>
            {paymentHistory.length === 0 ? (
                <div className="no-statements-message">
                    <p>No purchase statements available yet.</p>
                </div>
            ) : (
                <>
                    <table className="payment-history-table">
                        <thead>
                        <tr>
                            <th>Purchase Type</th>
                            <th>Detais</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Receipt</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentPurchases.map((payment, index) => (
                            <tr key={index}>
                                <td data-label="Purchase Type">{payment.purchaseType}</td>
                                <td data-label="Details">{payment.paymentDescription}</td>
                                <td data-label="Amount">${payment.amount}</td>
                                <td data-label="Date">
                                    {new Date(payment.purchaseDate).toLocaleDateString()}
                                </td>
                                <td data-label="Time">
                                    {new Date(payment.purchaseDate).toLocaleTimeString()}
                                </td>
                                <td data-label="Action">
                                    <button className="download-button"
                                            onClick={() => {
                                                handleDownloadReceipt(user, payment.id);
                                            }}>Download
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {[...Array(totalPurchasePages)].map((_, index) => (
                            <button
                                key={index}
                                className={
                                    currentPagePurchases === index + 1 ? "active-page" : ""
                                }
                                onClick={() => handlePurchasePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </section>
    );
};

export default UserStatements;
