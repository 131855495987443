import React, {useEffect, useState} from "react";
import "./UserCabinet.css";
import "../../pageComponents/headerFooter/HeaderSection.css";
import userSvg from "../../pageComponents/icons/svg/Logo_SB_User_Portfolio.svg";
import userPremiumSvg from "../../pageComponents/icons/svg/Logo_SB_User_Portfolio_Premium.svg";
import houseSvg from "../../pageComponents/icons/svg/Logo_SB_House_Portfolio.svg";
import housePremiumSvg from "../../pageComponents/icons/svg/Logo_SB_House_Portfolio_Premium.svg";

import Header from "../../pageComponents/headerFooter/Header";
import Footer from "../../pageComponents/headerFooter/Footer";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../../loader/Loader";
import CachedIcon from '@mui/icons-material/Cached';

import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setLogin, addUser} from "../../reduxStore/slices/GetUserSlice";

import Cookies from "universal-cookie";
import BackHomeBtn from "../../pageComponents/backHomeBtn/backHomeBtn";
import {useHandleLogout} from "../../utils/LogoutUtils";
import {recoveryPasswordAlertState, recoveryPasswordInfo} from "../../reduxStore/slices/AccountChangeAlertSlice";
import emailjs from "emailjs-com";

const UserCabinet = () => {

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setLoading] = useState(null);
    const [isRestartDisabled, setIsRestartDisabled] = React.useState(false);
    const {hash} = useLocation();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    axios.defaults.withCredentials = true;

    const [isBugPopupOpen, setIsBugPopupOpen] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        phone: "",
        email: "",
        domain: "",
        reqType: "",        // Only Bug Location needs to be filled
        location: "",        // Only Bug Location needs to be filled
        description: "",
        contactMethod: "email", // Default to "email"
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsBugPopupOpen(false);
        const updatedFormData = {
            ...formData,
            username: user?.username || "",
            email: user?.email || "",
            phone: user?.phone || "",
            domain: user?.sbHouseDomain || "",
        };

        emailjs.send(`${process.env.REACT_APP_EMAILJS_SERVICE}`, `${process.env.REACT_APP_EMAILJS_BUG_FORM_TEMPLATE}`, updatedFormData, `${process.env.REACT_APP_EMAILJS_USER_ID}`)
            .then(() => {
                alert("Bug report sent successfully!");
                // setIsBugPopupOpen(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("Failed to send bug report.");
            });
    };

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});

            }
        }
    }, [hash]);

    // Check if the user is on a mobile device
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        setIsMobile(/iphone|ipod|android/i.test(userAgent));
    }, []);
    // Home Assistant app URL
    const homeAssistantUrl = `homeassistant:///navigate`;

    useEffect(() => {
        try {
            // if (isLogin) {
            const usatCookie = cookies.get("usat");
            if (!usatCookie) {
                navigate(`/login`);
            }
            // }
        } catch (e) {
            console.error(e);
        }
    },);


    useEffect(() => {
        // Check if a timestamp exists in localStorage
        const lastClickTime = localStorage.getItem('restartTimestamp');
        if (lastClickTime) {
            const currentTime = Date.now();
            const timeElapsed = currentTime - parseInt(lastClickTime, 10);
            if (timeElapsed < 300000) { // 5 minutes = 300,000 ms
                setIsRestartDisabled(true);
                setTimeout(() => {
                    setIsRestartDisabled(false);
                    localStorage.removeItem('restartTimestamp'); // Clean up after 5 minutes
                    let username
                    let houseDomain
                    if (cookies.get("usat")) {
                        const usat = document.cookie
                            .split('; ')
                            .find(row => row.startsWith('usat='))
                            ?.split('=')[1];
                        const tokenParts = usat.split('.');
                        if (tokenParts.length === 3) {
                            const payload = JSON.parse(atob(tokenParts[1])); // Decode Base64 payload
                            username = payload.username; // Extract username
                            houseDomain = payload.sbHouseDomain; // Extract username
                        } else {
                            console.error("Invalid JWT format.");
                        }
                        setLoading(true);

                        const houseData = {
                            username: username,
                            houseDomain: houseDomain
                        };

                        try {
                            axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/user-version-update`,
                                houseData, {
                                    headers:
                                        {
                                            Authorization: `${usat}`, // Use the token in the Authorization header
                                        },
                                    withCredentials: true
                                },
                                {timeout: 5000})
                                .then(async (res) => {
                                    await updateUsatCookies(res);
                                    await updateVersionCookies(res);
                                })
                                .catch((e) => {
                                    console.log("something went wrong");
                                });
                        } catch (e) {
                            console.error(e);
                            setLoading(false);
                        }
                        setLoading(false);
                    }
                }, 300000 - timeElapsed);
            }
        }
    }, []);

    // Show the disclaimer after the component is mounted

    const logoutToLoginPage = () => {
        dispatch(addUser(null));
        dispatch(setLogin(false));
        cookies.remove("usat");
        cookies.remove("sbVersion");
        navigate(`/login`);
    }
    const updateUsatCookies = async (res) => {
        await cookies.remove("usat");
        await cookies.set('usat', res.data.token, {
            path: '/',                // Cookie is available to the entire domain
            secure: true,             // Only send the cookie over HTTPS
            maxAge: 60 * 60 * 24 * 30,  // 30 days
        });
        window.location.reload();
    }

    const updateVersionCookies = async (res) => {
        await cookies.remove("sbVersion");
        await cookies.set('sbVersion', res.data.sbVersion, {
            path: '/',
            secure: true,             // Only send the cookie over HTTPS
            maxAge: 60 * 60 * 24 * 30,  // 30 days
        });
        window.location.reload();
    }

// Logout Function
    // Logout Function
    const handleLogout = useHandleLogout();

    const handleHouseRestart = async () => {
        setIsRestartDisabled(true)
        setLoading(true);
        setTimeout(() => {
            setIsRestartDisabled(false);
            localStorage.removeItem('restartTimestamp'); // Clean up after 5 minutes
            if (cookies.get("usat")) {
                const usat = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('usat='))
                    ?.split('=')[1];

                setLoading(true);
                try {
                    axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/update-token?username=${user.username}`,
                        {
                            headers:
                                {
                                    Authorization: `${usat}`, // Use the token in the Authorization header
                                },
                            withCredentials: true
                        },
                        {timeout: 5000})
                        .then(async (res) => {
                            await updateUsatCookies(res);
                        })
                        .catch((e) => {
                            console.log("something went wrong");
                        });
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                }
                setLoading(false);
            }
        }, 300000);
        if (cookies.get("usat")) {
            const houseData = {
                username: user.username,
                houseDomain: user.sbHouseDomain,
            };
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {

                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/user-home-restart`,
                    houseData, {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    },
                    {timeout: 5000});
                if (res.status === 200) {
                    console.log("Success!");
                    setLoading(false);
                } else if (res.status === 404) {
                    alert(res.data.message);
                    setLoading(false);
                } else {
                    console.log("Unexpected success status:", res.status);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    setLoading(false);
                }

            } catch (e) {
                setLoading(false);
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    if (status === 401) {
                        console.log("Authorization error");
                        logoutToLoginPage();
                    } else if (status === 404) {
                        console.log("User not found");
                        alert(e.response.data.message);
                    } else if (status === 500) {
                        console.log("Failed to refresh the subscription");
                        alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    } else {
                        console.log("Unhandled error:", status);
                    }
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                }
            }
        }
    };

    const handleRefreshVersion = async () => {
        setLoading(true);
        const houseData = {
            username: user.username,
            houseDomain: user.sbHouseDomain
        };
        setTimeout(() => {
            setIsRestartDisabled(false);
            localStorage.removeItem('restartTimestamp'); // Clean up after 5 minutes
            if (cookies.get("usat")) {
                const usat = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('usat='))
                    ?.split('=')[1];

                setLoading(true);
                try {
                    axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/user-version-update`,
                        houseData, {
                            headers:
                                {
                                    Authorization: `${usat}`, // Use the token in the Authorization header
                                },
                            withCredentials: true
                        },
                        {timeout: 5000})
                        .then(async (res) => {
                            await updateUsatCookies(res);
                            await updateVersionCookies(res);
                        })
                        .catch((e) => {
                            console.log("something went wrong");
                        });
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                }
                setLoading(false);
            }
        }, 300000);
        if (cookies.get("usat")) {
            console.log("Refreshing status...");
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {

                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/user-version-update`,
                    houseData, {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    },
                    {timeout: 5000});
                if (res.status === 200) {
                    console.log("Success!");
                    await updateUsatCookies(res);
                    await updateVersionCookies(res);
                    setLoading(false);
                } else if (res.status === 404) {
                    alert(res.data.message);
                    setLoading(false);
                } else {
                    console.log("Unexpected success status:", res.status);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    setLoading(false);
                }

            } catch (e) {
                setLoading(false);
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    if (status === 401) {
                        console.log("Authorization error");
                        logoutToLoginPage();
                    } else if (status === 404) {
                        console.log("User not found");
                        alert(e.response.data.message);
                    } else if (status === 500) {
                        console.log("Failed to refresh the subscription");
                        alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    } else {
                        console.log("Unhandled error:", status);
                    }
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                }
            }
        }
    };

    const handleRefreshStatus = async () => {
        setLoading(true);
        if (cookies.get("usat")) {
            console.log("Refreshing status...");
            const houseData = {
                username: user.username,
                sbStatus: user.sbStatus,
                sbHouseStatus: user.sbHouseStatus,
            };
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {
                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/frontHomeStatusUpdate`,
                    houseData, {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    },
                    {timeout: 5000});

                if (res.status === 200) {
                    console.log("Success!");
                    setLoading(true);
                    try {
                        await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/update-token?username=${user.username}`,
                            {
                                headers:
                                    {
                                        Authorization: `${usat}`, // Use the token in the Authorization header
                                    },
                                withCredentials: true
                            },
                            {timeout: 5000})
                            .then(async (res) => {
                                await updateUsatCookies(res);
                            })
                            .catch((e) => {
                                console.log("something went wrong");
                            });
                    } catch (e) {
                        console.error(e);
                        setLoading(false);
                    }
                    setLoading(false);
                } else if (res.status === 404) {
                    alert(res.data.message);
                    setLoading(false);

                } else {
                    console.log("Unexpected success status:", res.status);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    setLoading(false);
                }

            } catch (e) {
                setLoading(false);
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    if (status === 401) {
                        console.log("Authorization error");
                        logoutToLoginPage();
                    } else if (status === 404) {
                        console.log("User not found");
                        alert(e.response.data.message);
                    } else if (status === 500) {
                        console.log("Failed to refresh the subscription");
                        alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                    } else {
                        console.log("Unhandled error:", status);
                    }
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Operation wasn't successful. Make sure your Smart Home is up and connected to internet.");
                }
            }
        }
    };

    const handleSettings = () => {
        setLoading(true);
        // Clear cookies or session tokens if necessary.send()
        navigate(`/cabinet/${user.username}/settings`);
        setLoading(false);
    };
    const handleBillings = () => {
        setLoading(true);
        // Clear cookies or session tokens if necessary
        navigate(`/cabinet/${user.username}/billings`);
        setLoading(false);
    };

    // Handle the subscription button click
    const handleSubscription = async () => {
        setLoading(true);
        const subscriptionData = {
            userName: user.username,
            userEmail: user.email,
        };
        if (cookies.get("usat")) {
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {
                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/create-subscription-plan`,
                    subscriptionData,
                    {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    }, {timeout: 5000});
                if (res.status === 200) {
                    console.log("Subscribed");
                    window.location.href = res.data;
                } else {
                    console.log("Unexpected status:", res.status);
                    window.location.href = res.data;
                    alert("Unexpected error! Operation wasn't successful. Please contact us.");
                    logoutToLoginPage();
                }

            } catch (e) {
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    if (status === 401) {
                        console.log("Authorization error");
                        logoutToLoginPage();
                    } else if (status === 404) {
                        console.log("User not found");
                        logoutToLoginPage();
                    } else if (status === 500) {
                        console.log("Failed to create subscription");
                    } else {
                        console.log("Unhandled error:", status);
                    }
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                }
            }
        }
        setLoading(false);

    };
    // Handle the subscription button click
    const handleUnSubscription = async () => {
        setLoading(true);
        const subscriptionData = {
            userName: user.username,
            userEmail: user.email,
        };

        try {
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            const res = await axios.post(
                `${process.env.REACT_APP_SERVER_DOMAIN}/un-subscription-plan`,
                subscriptionData,
                {
                    headers:
                        {
                            Authorization: `${usat}`, // Use the token in the Authorization header
                        },
                    withCredentials: true
                }, {timeout: 5000});

            if (res.status === 200) {
                console.log("unsubscribed");
                await updateUsatCookies(res);
            } else if (res.status === 422) {
                console.log("unsubscribed");
                await updateUsatCookies(res);
            } else {
                console.log("Unexpected success status:", res.status);
                alert("Unexpected error! Operation wasn't successful. Please try again.");
            }

        } catch (e) {
            if (e.response) {
                // Server responded with a status outside the 2xx range
                const {status} = e.response;

                if (status === 401) {
                    console.log("Authorization error");
                    logoutToLoginPage();
                } else if (status === 404) {
                    console.log("User not found");
                    logoutToLoginPage();
                } else if (status === 500) {
                    console.log("Failed to create subscription");
                } else {
                    console.log("Unhandled error:", status);
                }
                alert("Unexpected error! Operation wasn't successful. Please try again.");
            } else {
                // Network or unexpected error
                console.error("Unexpected error:", e.message || e);
                alert("Unexpected error! Operation wasn't successful. Please try again.");
            }
        }
        setLoading(false);

    };

    return !isLoading ? (
        <div>
            <Header titleName="User Cabinet"/>
            <div className="cabinet-page">

                <section className="cabinet-content">
                    <div className="id-container">
                        {/* User Profile ID */}

                        <div className={`${user?.sbStatus === "premium" ||
                        user?.sbStatus === "admin" ||
                        user?.sbStatus === "uat"
                            ? "id-card-premium" : ""}  
                            // if true
                            ${isBugPopupOpen ?
                            // than select style
                            'disable-hover-id-card' : 'id-card'} user-id`}>
                            <img
                                src={user?.image ? user.image : user?.sbStatus === "premium" ||
                                user?.sbStatus === "admin" ||
                                user?.sbStatus === "uat" ? userPremiumSvg : userSvg}
                                alt="User Profile"
                                className="id-photo"
                            />
                            <div className="id-info">
                                <h3 className={user?.fullUserName ? "" : "highlight-red"}>Name: {user?.fullUserName ? user.fullUserName : "Set full name"}</h3>
                                <p className={user?.email ? "" : "highlight-red"}>Email: {user?.email ? user.email : "Set email"}</p>
                                <p className={user?.phone ? "" : "highlight-red"}>Phone: {user?.phone ? user.phone : "Set phone"}</p>
                                <p className={user?.sbStatus ? "" : "highlight-red"}>Status: {user?.sbStatus ? user.sbStatus : "Set Status"}</p>
                                <a onClick={() => setIsBugPopupOpen(true)}>
                                    Suggest & Report
                                </a>

                                {isBugPopupOpen && (
                                    <div className="user-cabinet-popup">
                                        <div className="user-cabinet-popup-content">
                                            <h2>Help Us Improve</h2>
                                            <form onSubmit={handleSubmit}>
                                                <label>
                                                    Improve or Report:
                                                    <select
                                                        name="reqType"
                                                        value={formData.reqType}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="report">Report</option>
                                                        <option value="improve">Improve</option>
                                                    </select>
                                                </label>

                                                <label>
                                                    Location:
                                                    <select
                                                        name="location"
                                                        value={formData.location}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">Select Bug Type</option>
                                                        <option value="site">Site</option>
                                                        <option value="house_system">House System</option>
                                                    </select>
                                                </label>

                                                <label>
                                                    Preferred Contact Method:
                                                    <select
                                                        name="contactMethod"
                                                        value={formData.contactMethod}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="email">Email</option>
                                                        <option value="phone">Phone</option>
                                                    </select>
                                                </label>

                                                <label>
                                                    Description:
                                                    <textarea
                                                        style={{resize: "none"}}
                                                        name="description"
                                                        value={formData.description}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </label>

                                                <div className="user-cabinet-btn-container">
                                                    <button type="submit" className="menu-btn settings-btn">
                                                        Send
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="menu-btn logout-btn"
                                                        onClick={() => setIsBugPopupOpen(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {/*<a href={user?.link ? user.link : "link is unavailable"}target="_blank" rel="noopener noreferrer">*/}
                                {/*    View Profile*/}
                                {/*</a>*/}
                            </div>

                        </div>

                        {/* Home Status ID */}
                        <div className="id-card home-id">
                            <div className="id-info">
                                <h3 className={user?.sbHouseDomain ? "" : "highlight-red"}>Domain: {user?.sbHouseDomain ? user.sbHouseDomain : "Home Name is not set"}</h3>
                                <p className={user?.sbHouseAddress ? "" : "highlight-red"}>Address: {user?.sbHouseAddress ? user.sbHouseAddress : "Home Location not set"}</p>
                                <p className={user?.sbHousePackage ? "" : "highlight-red"}>Package: {user?.sbHousePackage ? user.sbHousePackage : "Home Package not set"}</p>
                                <p className={user?.sbHouseStatus ? "" : "highlight-red"}>Status: {user?.sbHouseStatus ? user.sbHouseStatus : "Home Status not set"}

                                    {/* Conditional Refresh Icon */}
                                    {user?.sbHouseStatus && user?.sbStatus && ((user?.sbHouseStatus === 'basic' && user?.sbStatus !== 'basic') || (user?.sbHouseStatus === 'premium' && user?.sbStatus === 'basic')) && (
                                        <span className="refresh-icon" onClick={() => {
                                            const isConfirmed = window.confirm("This refresh will sync your home status with your account status. Do you want to continue?");
                                            if (isConfirmed) {
                                                handleRefreshStatus();
                                            }
                                        }
                                        }
                                              style={{
                                                  padding: '10px',
                                                  pointerEvents: isRestartDisabled ? 'none' : 'auto',
                                                  opacity: isRestartDisabled ? 0.5 : 1,
                                                  cursor: isRestartDisabled ? 'not-allowed' : 'pointer',
                                              }}
                                              title="Refresh Status">
                                        <CachedIcon fontSize="medium"/>
                                    </span>
                                    )}

                                </p>
                                <p className={user?.sbHouseVersion ? "" : "highlight-red"}>Version: {user?.sbHouseVersion ? user.sbHouseVersion : "Home Version not set"}

                                    {/* Conditional Refresh Icon */}
                                    {user?.sbHouseVersion && (user?.sbHouseVersion !== cookies.get('sbVersion')) && (
                                        <span className="refresh-icon" onClick={() => {
                                            const isConfirmed = window.confirm("This refresh will sync your home status with your account status. Do you want to continue?");
                                            if (isConfirmed) {
                                                localStorage.setItem('restartTimestamp', Date.now().toString());
                                                handleRefreshVersion();
                                            }
                                        }
                                        }
                                              style={{
                                                  padding: '10px',
                                                  pointerEvents: isRestartDisabled ? 'none' : 'auto',
                                                  opacity: isRestartDisabled ? 0.5 : 1,
                                                  cursor: isRestartDisabled ? 'not-allowed' : 'pointer',
                                              }}
                                              title="Refresh Status">
                                        <CachedIcon fontSize="medium"/>
                                    </span>
                                    )}

                                </p>
                                {user?.sbHouseDomain && (
                                    <a
                                        href={isMobile ? homeAssistantUrl : `https://${user.sbHouseDomain}.smartbirdsolution.com`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{paddingRight: '10px'}}

                                    >
                                        View Home
                                    </a>
                                )}
                                |
                                {user?.sbHouseDomain && (
                                    <a
                                        onClick={() => {
                                            const isConfirmed = window.confirm("This function will restart your house system. Do you want to continue?");
                                            if (isConfirmed) {
                                                localStorage.setItem('restartTimestamp', Date.now().toString());
                                                handleHouseRestart();
                                            }
                                        }
                                        }
                                        style={{
                                            padding: '10px',
                                            pointerEvents: isRestartDisabled ? 'none' : 'auto',
                                            opacity: isRestartDisabled ? 0.5 : 1,
                                            cursor: isRestartDisabled ? 'not-allowed' : 'pointer',
                                        }}
                                    >
                                        Restart Home
                                    </a>
                                )}
                            </div>
                            <img
                                src={user?.image ? user.image : user?.sbHouseStatus === "premium" ? housePremiumSvg : houseSvg}
                                alt="Home"
                                className="id-photo home-photo"
                            />
                        </div>
                    </div>

                    <div className="user-menu">
                        <button
                            onClick={handleSettings}
                            className="menu-btn settings-btn"
                        >
                            Settings
                        </button>
                        {user ? (
                            user.sbStatus === "basic" ? (
                                <button
                                    onClick={() => {
                                        const isConfirmed = window.confirm("Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our Privacy Policy page and Terms Of Service page(can be found bellow). Please confirm if you want to subscribe?");
                                        if (isConfirmed) {
                                            handleSubscription();
                                        }
                                    }}
                                    className="menu-btn settings-btn"
                                >
                                    Subscribe
                                </button>
                            ) : user.sbStatus === "premium" ? (
                                <button
                                    onClick={() => {
                                        const isConfirmed = window.confirm("Are you sure you want to unsubscribe?");
                                        if (isConfirmed) {
                                            handleUnSubscription();
                                        }
                                    }}
                                    className="menu-btn settings-btn"
                                >
                                    UnSubscribe
                                </button>
                            ) : user.sbStatus === "admin" ? (
                                <button
                                    onClick={() => {
                                        navigate(`/cabinet/${user.username}/admin-page`);
                                    }}
                                    className="menu-btn settings-btn"
                                >
                                    Admin Page
                                </button>
                            ) : (
                                <button
                                    className="menu-btn settings-btn"
                                >
                                    Thank you
                                </button>
                            )
                        ) : (
                            <a href="/login">Login</a>
                        )}
                        <button
                            onClick={handleBillings}
                            className="menu-btn settings-btn"
                        >
                            Billing
                        </button>
                        <button
                            onClick={handleLogout}
                            className="menu-btn logout-btn"
                        >
                            Logout
                        </button>

                    </div>
                </section>
                <BackHomeBtn/>

            </div>
            <Footer/>
        </div>
    ) : (
        <Loader/>
    );
};

export default UserCabinet;
