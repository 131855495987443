import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import "./PaymentSuccess.css";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Loader from "../../loader/Loader";
import Cookies from "universal-cookie";
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {jsPDF} from "jspdf";

const PaymentSuccess = () => {
    const [successResponse, setSuccessResponse] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);
    const requestMade = useRef(false);
    const [isLoading, setLoading] = useState(null);

    const cookies = new Cookies();

    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    const handleNavigate = () => {
        // Navigate to the user's settings page
        navigate(user ? `/cabinet/${user.username}/home` : '/login');
        window.location.reload();
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.text("Receipt", 105, 20, null, null, "center");
        // Parse the userPurchaseTime string to create a Date object
        const userPurchaseTime = new Date(successResponse?.userPurchaseTime);
        const formattedDate = `${(userPurchaseTime.getMonth() + 1).toString().padStart(2, '0')}/${userPurchaseTime.getDate().toString().padStart(2, '0')}/${userPurchaseTime.getFullYear()}`;
        const formattedTime = `${userPurchaseTime.getHours().toString().padStart(2, '0')}:${userPurchaseTime.getMinutes().toString().padStart(2, '0')}:${userPurchaseTime.getSeconds().toString().padStart(2, '0')}`;

        doc.text(`Date: ${formattedDate}`, 105, 30, null, null, "center");

        doc.text("Company Name: Smart Bird Solution", 20, 50);
        doc.text("Address: 123 Smart St, Smart City", 20, 60);
        doc.text("Return Policy: Varies by product type.", 20, 70);
        doc.text("If you have questions about charges, email: smartbirdservice@gmail.com", 20, 80);
        doc.text("Payment was processed securely via PayPal.", 20, 90);
        doc.text("You can find your PayPal receipt in your PayPal account.", 20, 100);

        doc.text(`Paypal Invoice Number: ${successResponse?.invoiceOrderNumber}`, 20, 120);

        doc.text("Sale Transaction", 20, 130);
        doc.text(`Purchase Type: ${successResponse?.productType}`, 20, 140);
        doc.text(`Payment Id: ${successResponse?.subId}`, 20, 150);
        doc.text(`Status: ${successResponse?.subStatus}`, 20, 160);
        doc.text(`Price: $ ${successResponse?.purchaseValue}`, 20, 170);
        doc.text("Tax: Included", 20, 180);
        doc.text(`Total Price: $ ${successResponse?.purchaseValue}`, 20, 190);

        doc.text("Guest Copy", 105, 220, null, null, "center");
        doc.text("Thank you!", 105, 230, null, null, "center");
        doc.text(`Date & Time: ${formattedDate} ${formattedTime}`, 105, 240, null, null, "center");

        doc.setTextColor(0, 0, 265); // Set text color to blue (RGB)
        doc.textWithLink('Privacy Policy', 20, 260, {url: 'https://www.smartbirdsolution.com/privacy-policy/'});
        doc.textWithLink('Terms Of Service', 20, 270, {url: 'https://www.smartbirdsolution.com/terms-of-service/'});

        doc.save("Receipt.pdf");
    };

    useEffect(() => {
        setLoading(true)
        try {
            if (requestMade.current) return; // Prevent duplicate execution
            requestMade.current = true;

            const searchParams = new URLSearchParams(window.location.search);
            const paymentId = searchParams.get("paymentId");
            const PayerID = searchParams.get("PayerID");

            const token = searchParams.get("token");


            const subscriptionid = searchParams.get("subscription_id");
            const baToken = searchParams.get("ba_token");

            let rType = searchParams.get("rType")

            if (!paymentId && !PayerID){
                rType = "user_subscription"
            }
            if (!subscriptionid && !baToken){
                rType = "user_order"
            }


            if (!rType) {
                navigate(user ? `/cabinet/${user.username}/home` : '/login');
                window.location.reload();
            }


            if (cookies.get("usat")) {
                try {
                    const usat = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('usat='))
                        ?.split('=')[1];
                    switch (rType) {
                        case 'user_order':
                            axios
                                .get(
                                    `${process.env.REACT_APP_SERVER_DOMAIN}/payment-success?rType=${rType}&paymentId=${paymentId}&token=${token}&PayerID=${PayerID}`, {
                                        headers:
                                            {
                                                Authorization: `${usat}`, // Use the token in the Authorization header
                                            },
                                        withCredentials: true
                                    }
                                )
                                .then(async (res) => {

                                    if (res.status === 200) {
                                        setSuccessResponse(res.data.dataInfo);
                                        localStorage.removeItem("ordersHistory");
                                        localStorage.removeItem("subscriptionsTimestamp");
                                        localStorage.removeItem("paymentHistory");
                                        localStorage.removeItem("paymentHistoryTimestamp");
                                        // window.location.reload();
                                        setLoading(false);
                                    }
                                })
                                .catch(({response}) => {
                                    if ((response.status === 500 && response.data.error === "Error executing payment") || (response.status === 400 && response.data.error === "Payment already was made")) {
                                        setSuccessResponse(response.data.dataInfo);
                                        localStorage.removeItem("ordersHistory");
                                        localStorage.removeItem("subscriptionsTimestamp");
                                        localStorage.removeItem("paymentHistory");
                                        localStorage.removeItem("paymentHistoryTimestamp");
                                        // window.location.reload();
                                        setLoading(false);

                                    }else {
                                        alert(response.data.error);
                                        navigate(user ? `/cabinet/${user.username}/home` : '/login');
                                        window.location.reload();
                                        setErrorResponse(response.data.error);
                                    }

                                });
                            break;
                        case 'user_subscription':
                            axios
                                .get(
                                    `${process.env.REACT_APP_SERVER_DOMAIN}/payment-success?rType=${rType}&subscription_id=${subscriptionid}&ba_token=${baToken}&token=${token}`, {
                                        headers:
                                            {
                                                Authorization: `${usat}`, // Use the token in the Authorization header
                                            },
                                        withCredentials: true
                                    }
                                )
                                .then(async (res) => {
                                    setSuccessResponse(res.data.dataInfo);

                                    if (res.status === 200) {
                                        localStorage.removeItem("paymentHistory");
                                        localStorage.removeItem("paymentHistoryTimestamp");
                                        await cookies.remove("usat");
                                        // await cookies.set("usat", res.data.token);
                                        await cookies.set('usat', res.data.token, {
                                            path: '/',                // Cookie is available to the entire domain
                                            secure: true,             // Only send the cookie over HTTPS
                                            maxAge: 60 * 60 * 24 * 30,  // 30 days
                                        });
                                        setLoading(true);
                                        setTimeout(async () => {
                                            try {
                                                await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/update-token?username=${res.data.username}`,
                                                    {
                                                        headers:
                                                            {
                                                                Authorization: `${usat}`, // Use the token in the Authorization header
                                                            },
                                                        withCredentials: true
                                                    },
                                                    {timeout: 5000})
                                                    .then(async (res) => {
                                                        await cookies.remove("usat");
                                                        // await cookies.set("usat", res.data.token);
                                                        await cookies.set('usat', res.data.token, {
                                                            path: '/',                // Cookie is available to the entire domain
                                                            secure: true,             // Only send the cookie over HTTPS
                                                            maxAge: 60 * 60 * 24 * 30,  // 30 days
                                                        });
                                                    })
                                                    .catch((e) => {
                                                        console.log("something went wrong");
                                                    });
                                            } catch (e) {
                                                console.error(e);
                                                setLoading(false);
                                            }
                                            setLoading(false);
                                        }, 5000);


                                        // window.location.reload();
                                    }
                                })
                                .catch(({response}) => {
                                    if ((response.status === 400 && response.data.error === "Payment already was made")) {
                                        setSuccessResponse(response.data.dataInfo);
                                        localStorage.removeItem("ordersHistory");
                                        localStorage.removeItem("subscriptionsTimestamp");
                                        localStorage.removeItem("paymentHistory");
                                        localStorage.removeItem("paymentHistoryTimestamp");
                                        // window.location.reload();
                                        setLoading(false);
                                    }else {
                                        alert(response.data.error);
                                        navigate(user ? `/cabinet/${user.username}/home` : '/login');
                                        window.location.reload();
                                        setErrorResponse(response.data.error);
                                    }
                                });
                            break;
                        default:
                            navigate(user ? `/cabinet/${user.username}/home` : '/login');
                            window.location.reload();
                            setLoading(false);
                            break;
                    }

                } catch (e) {
                    console.error("Unexpected error:", e);
                }
            }
        } catch (e) {
            console.error(e);
            setLoading(false)
        }
        setLoading(false)
    }, []);

    useEffect(() => {

    }, [successResponse]);

    return (successResponse || errorResponse) && !isLoading ? (
        <div className="container">
            <div className="receipt">
                <div className="paper">

                    <div className="paper-title-wrapper">
                        <div className="title-receipt">Receipt</div>
                        <div className="date">
                            {/* Parse the userPurchaseTime string and format the date */}
                            {(() => {
                                const userPurchaseTime = new Date(successResponse?.userPurchaseTime);
                                const formattedDate = `${(userPurchaseTime.getMonth() + 1).toString().padStart(2, '0')}/${userPurchaseTime.getDate().toString().padStart(2, '0')}/${userPurchaseTime.getFullYear()}`;
                                return `Date: ${formattedDate}`;
                            })()}
                        </div>
                    </div>

                    <div className="company-info">
                        <p>Company Name: Smart Bird Solution</p>
                        <p>Address: 123 Smart St, Smart City</p>
                        <p>Return Policy: Varies by product type and company policies.</p>
                        <p>If you have questions about charges, email: support@smartbird.com</p>
                        <p>Payment was processed securely via PayPal. You can find your PayPal receipt in your PayPal
                            account.</p>
                    </div>

                    <table className="table-success">
                        <tbody className="successtbody">
                        <tr>
                            <td>Purchase Type</td>
                            <td className="right">{successResponse?.productType}</td>
                        </tr>
                        <tr>
                            <td>Paypal Invoice Number</td>
                            <td className="right">{successResponse?.invoiceOrderNumber}</td>
                        </tr>                        <tr>
                            <td>Payment Id</td>
                            <td className="right">{successResponse?.subId}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td className="right">{successResponse?.subStatus}</td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td className="right">$ {successResponse?.purchaseValue}</td>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <td className="right">INCLUDED</td>
                        </tr>
                        <tr>
                            <td>Total Price</td>
                            <td className="right">$ {successResponse?.purchaseValue}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="thank-you">Guest Copy: Thank you!</div>
                    <div className="date-time">
                        {/* Parse the userPurchaseTime string and format both date and time */}
                        {(() => {
                            const userPurchaseTime = new Date(successResponse?.userPurchaseTime);
                            const formattedDate = `${(userPurchaseTime.getMonth() + 1).toString().padStart(2, '0')}/${userPurchaseTime.getDate().toString().padStart(2, '0')}/${userPurchaseTime.getFullYear()}`;
                            const formattedTime = `${userPurchaseTime.getHours().toString().padStart(2, '0')}:${userPurchaseTime.getMinutes().toString().padStart(2, '0')}:${userPurchaseTime.getSeconds().toString().padStart(2, '0')}`;
                            return `Date & Time: ${formattedDate} ${formattedTime}`;
                        })()}
                    </div>

                    <div className="buttons-success">
                        <button className="button-success" onClick={handleNavigate} style={{transition: '.3s'}}>
                            Return to personal office
                        </button>
                        <button className="button-success" onClick={generatePDF} style={{transition: '.3s'}}>
                            Download PDF
                        </button>
                    </div>
                    <div className="policy-info">
                        <a href="/privacy-policy/" className="policy-a-right">Privacy Policy</a> |
                        <a href="/terms-of-service/" className="policy-a-left">Terms of Service</a>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Loader/>
    );
};

export default PaymentSuccess;
