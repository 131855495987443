import React from "react";
import "./PrivacyPolicy.css";
import Header from "../pageComponents/headerFooter/Header";
import Footer from "../pageComponents/headerFooter/Footer";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-page">
            <Header titleName="Privacy Policy" />
            <section className="privacy-content">
                <h2>Privacy Statement</h2>
                <p>----<br/><br/></p>

                <h3>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h3>
                <p>
                    When you provide us with personal information and order our services to install smart devices in your home, we collect the details you share, including your name, address, email address, home plans, and other personal information. This information is used to complete the installation process, offer ongoing support, and provide additional services upon request.
                    When you browse our web application, we also automatically receive your computer’s Internet Protocol (IP) address, which helps us understand more about your browser and operating system for better service.
                    Email Marketing (if applicable): With your consent, we may send you emails about our services, new products, and other updates.
                </p>

                <h3>SECTION 2 - CONSENT</h3>
                <h4>How do you get my consent?</h4>
                <p>
                    When you provide us with personal information to complete a service request, place an order, transaction, verify your credit or debit card or any other bank card, bank check, we imply that you consent to our collecting it and using it for that specific reason only.
                    If we ask for your personal information for a secondary reason, such as marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                </p>

                <h4>How do I withdraw my consent?</h4>
                <p>
                    If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use, or disclosure of your information, at any time, by contacting us at SmartBirdService@gmail.com.
                </p>

                <h3>SECTION 3 - DISCLOSURE</h3>
                <p>
                    We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                </p>

                <h3>SECTION 4 - THIRD-PARTY SERVICES</h3>
                <p>
                    Our services include setting up smart homes using Home Assistant, along with additional Home Assistant add-ons and integrations. We also use Amazon Web Services, including Route 53 for domain management and Lambda, as well as PayPal for payment processing.

                    Home Assistant and Third-Party Devices:
                    We provide smart home setups using Home Assistant and may offer extra add-ons and integrations as part of our service. However, all additional devices required for our system must be purchased separately by the customer from third-party providers. We are not responsible for these devices, as the customer independently selects them.

                    Third-Party Providers:
                    In general, the third-party providers we use, such as PayPal, Home Assistant, and Amazon Web Services (including Route 53 and Lambda), will only collect, use, and disclose your information to the extent necessary to perform the services they provide to us. However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies regarding the information we are required to provide for your purchase-related transactions. We recommend that you review their privacy policies to understand how your personal information will be handled by these providers.

                    Customer Responsibility for External Access:
                    If a customer chooses to forgo a subscription with Smart Bird Solution, we will not provide access to our managed AWS domain services for external public access. Instead, customers may use third-party services like DuckDNS or other DNS providers for public access to their Home Assistant system. The customer assumes full responsibility for managing and maintaining any such services, and Smart Bird Solution is not liable for the security, reliability, or performance of these third-party solutions. By opting for third-party DNS services, the customer agrees to take on full responsibility for any issues or risks related to their external access setup, including, but not limited to, security breaches or loss of access.

                    Jurisdiction of Third-Party Providers:
                    Please note that certain third-party providers may be located in or have facilities that are located in a different jurisdiction than either you or us. If you proceed with a transaction that involves the services of a third-party provider, your information may become subject to the laws of the jurisdiction(s) where that provider or its facilities are located. For example, if you are located in Canada and your transaction is processed by a payment gateway in the United States, your personal information used in that transaction may be subject to disclosure under U.S. laws, including the Patriot Act.

                    Payment:
                    If you choose PayPal as your payment method, your credit card data will be processed by PayPal, adhering to their security standards. We do not store your payment details.

                    External Links:
                    When you click on links on our website, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.

                    Google Analytics:
                    Our website may use Google Analytics to help us learn about who visits our site and what pages are being looked at.
                </p>

                <h3>SECTION 5 - SECURITY</h3>
                <p>
                    To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                    If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL/TSL) and stored with a AES-256 encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.
                </p>

                <h3>SECTION 6 - COOKIES</h3>
                <p>
                    Here is a list of cookies that we use. We’ve listed them here so you that you can choose if you want to opt-out of cookies or not.
                    We are not using cookies yet, will be updated soon.
                    Google analytics:
                    PREF, persistent for a very short period, Set by Google and tracks who visits the store and from where
                </p>

                <h3>SECTION 7 - AGE OF CONSENT</h3>
                <p>
                    By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                </p>

                <h3>SECTION 8 - CHANGES TO THIS PRIVACY POLICY</h3>
                <p>
                    We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                    If our website is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                </p>

                <h3>QUESTIONS AND CONTACT INFORMATION</h3>
                <p>
                    If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at smartbirdservice@gmail.com.
                </p>
            </section>
            <BackHomeBtn/>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
