import React from "react";
import "./TermsOfService.css";
import Header from "../pageComponents/headerFooter/Header";
import Footer from "../pageComponents/headerFooter/Footer";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";

const TermsOfService = () => {
    return (
        <div className="terms-of-service-page">
            <Header titleName="Terms of Service"/>
            <section className="terms-content">
                <h2>Terms of Service</h2>

                <h3>OVERVIEW</h3>
                <p>
                    This website is operated by Smart Bird Solution. Throughout the site, the terms “we”, “us” and “our”
                    refer to Smart Bird Solution. We offer this website, including all information, tools, and services
                    available from this site to you, the user, conditioned upon your acceptance of all terms,
                    conditions, policies, and notices stated here.

                    By visiting our site and/or engaging with our services, you participate in our “Service” and agree
                    to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including any
                    additional terms and conditions and policies referenced herein and/or available by hyperlink. These
                    Terms of Service apply to all users of the site, including, without limitation, users who are
                    browsers, customers, and/or contributors of content.

                    Please read these Terms of Service carefully before accessing or using our website. By accessing or
                    using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to
                    all the terms and conditions of this agreement, then you may not access the website or use any
                    services. If these Terms of Service are considered an offer, acceptance is expressly limited to
                    these Terms of Service.

                    Any new features or tools which are added to the current website shall also be subject to the Terms
                    of Service. You can review the most current version of the Terms of Service at any time on this
                    page. We reserve the right to update, change, or replace any part of these Terms of Service by
                    posting updates and/or changes to our website. It is your responsibility to check this page
                    periodically for changes. Your continued use of or access to the website following the posting of
                    any changes constitutes acceptance of those changes.

                    Our website is controlled by us and hosted by Amazon Web Services (AWS). We provide services to
                    upgrade your home to a smart home and offer a subscription for ongoing support.

                    Service Termination and Client Relationships: Smart Bird Solution reserves the right to stop
                    providing services at any time or to reject providing services to specific clients if we believe
                    that maintaining a relationship is not feasible or in the best interest of either party. Likewise,
                    customers may choose to cease working with us at any time.
                </p>

                <h3>SECTION 1 - WEB TERMS</h3>
                <p>
                    By agreeing to these Terms of Service, you represent that you are at least the age of majority in
                    your state or province of residence, or that you are the age of majority in your state or province
                    of residence and you have given us your consent to allow any of your minor dependents to use this
                    site.
                    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the
                    Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                    You must not transmit any worms or viruses or any code of a destructive nature.
                    A breach or violation of any of the Terms will result in an immediate termination of your Services.
                </p>

                <h3>SECTION 2 - GENERAL CONDITIONS</h3>
                <p>
                    We reserve the right to refuse service to anyone for any reason at any time.
                    You understand that your content (not including credit card information), may be transferred
                    unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and
                    adapt to technical requirements of connecting networks or devices. Credit card information is always
                    encrypted during transfer over networks.
                    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use
                    of the Service, or access to the Service or any contact on the website through which the service is
                    provided, without express written permission by us.
                    The headings used in this agreement are included for convenience only and will not limit or
                    otherwise affect these Terms.
                </p>


                <h3>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h3>
                <p>
                    We are not responsible if information made available on this site is not accurate, complete or
                    current. The material on this site is provided for general information only and should not be relied
                    upon or used as the sole basis for making decisions without consulting primary, more accurate, more
                    complete or more timely sources of information. Any reliance on the material on this site is at your
                    own risk.
                    This site may contain certain historical information. Historical information, necessarily, is not
                    current and is provided for your reference only. We reserve the right to modify the contents of this
                    site at any time, but we have no obligation to update any information on our site. You agree that it
                    is your responsibility to monitor changes to our site.
                </p>

                <h3>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h3>
                <p>
                    Prices for our services are subject to change without prior notice. We reserve the right to modify
                    or discontinue any part of the Service at any time without notice. We shall not be liable for any
                    modifications, price changes, suspensions, or discontinuations of the Service.

                    If a customer decides to stop working with us, we will consider this as cessation of support for the
                    customer’s system, including the termination of all subscriptions and connections such as Amazon Web
                    Services. Additionally, if a customer makes any changes to the code or device, or alters any
                    hardware or software, we reserve the right to cease support for the customer or their devices
                    without offering any return or reimbursement.
                </p>

                <h3>SECTION 5 - INTELLECTUAL PROPERTY RIGHTS</h3>
                <p>
                    All content, features, and functionality of our website or application, including but not limited to
                    all text, images, video, audio, software, and the design, layout, and arrangement of these elements,
                    are owned by Smart Bird Solution or its licensors and are protected by United States and
                    international copyright, trademark, patent, trade secret, and other intellectual property laws.

                    These Terms of Service grant you a limited, non-exclusive, non-transferable license to access and
                    use our website or application for your personal, non-commercial purposes only. You may not
                    reproduce, distribute, modify, create derivative works from, publicly display, or perform any
                    material from our site without our prior written consent.

                    If our website or application includes features that allow you to link to or interact with social
                    media platforms such as Facebook, Twitter, Instagram, or YouTube, you are permitted to use those
                    features as intended. However, you must not:

                    Alter or modify any content from our website or application.
                    Use any visual or auditory content from our website or application separately from its accompanying
                    text.
                    Remove or change any copyright, trademark, or other proprietary notices from the content on our
                    website or application.
                    No rights, titles, or interests in or to our website or application or its content are transferred
                    to you under these Terms of Service. All rights not expressly granted are reserved by Smart Bird
                    Solution. Unauthorized use of the website or its content may constitute a breach of these Terms of
                    Service and could infringe on intellectual property rights.
                </p>

                <h3>SECTION 5 - PRODUCTS AND SERVICES</h3>
                <p>
                    Certain products and services may be available exclusively through our website. These offerings may
                    be limited in quantity and are subject to our Return Policy.

                    While we strive to accurately display the colors and images of our products, we cannot guarantee
                    that your computer monitor’s display will perfectly reflect the actual colors and details of the
                    products.

                    We reserve the right, at our sole discretion, to limit the availability of our products or services
                    to any individual, geographic location, or jurisdiction. This limitation may be applied on a
                    case-by-case basis. Additionally, we may restrict the quantity of products or services available for
                    purchase. All product descriptions and pricing are subject to change without notice. We also retain
                    the right to discontinue any product or service at any time. Offers for products or services made on
                    our site are void where prohibited.

                    Please note that our website may offer products from third-party vendors. While we provide software
                    to enhance the functionality of these products, we are not responsible for the products themselves.
                    Any returns for third-party products must be handled in accordance with the return policies of the
                    respective third-party vendors.

                    For returns of our software products, please refer to our specific Software Return Policy. We do not
                    guarantee that the quality of any products, services, or other materials purchased or obtained
                    through our website will meet your expectations, nor do we warrant that any errors in the services
                    will be corrected.
                </p>

                <h3>SECTION 6 - SOFTWARE RETURN POLICY</h3>
                <p>
                    Our return policy allows for returns within 30 days from the date of purchase.
                    Software Services: Returns are only applicable for our own software services. If you have purchased
                    third-party devices through us and have been charged for them, those devices are also eligible for
                    return under this policy. However, if the devices were purchased directly from third-party
                    providers, we are not responsible for handling returns or refunds for those items.
                    Additional Fees: Please note that a service call fee or cancellation fee may be deducted from your
                    refund amount. This fee is applied to cover any costs incurred during the service provision or
                    cancellation process.
                    Return Process: To initiate a return, please contact our customer support team within the 30-day
                    return window. You will need to provide proof of purchase and details about the service or software
                    you wish to return.
                    Exceptions: We reserve the right to refuse returns or issue partial refunds for services or software
                    if the return request does not comply with our policy. Additionally, any returns or refunds
                    processed for third-party devices will be subject to the return policies of the respective
                    third-party providers.
                    Refunds will be processed to the original payment method used at the time of purchase, by check, or
                    via Zelle. Please allow additional time for the refund to be reflected in your account.
                    If you have any questions or need further assistance regarding our return policy, please reach out
                    to our customer support team.
                </p>

                <h3>SECTION 7 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h3>
                <p>
                    We retain the right to refuse any order you place with us. At our sole discretion, we may limit or
                    cancel quantities purchased per person, per household, or per order. These limitations may apply to
                    orders placed by or under the same customer account, the same payment method, and/or orders that use
                    the same billing and/or shipping address. If we need to make changes to or cancel an order, we may
                    attempt to notify you by contacting the email and/or billing address or phone number provided at the
                    time the order was made. We also reserve the right to restrict or refuse orders that, in our
                    judgment, seem to be placed by dealers, resellers, or distributors.
                    You agree to provide accurate, current, and complete purchase and account information for all
                    transactions made through our store. You also agree to promptly update your account and any other
                    necessary information, including your email address, payment details, and credit card expiration
                    dates, to ensure we can complete your transactions and reach you if needed.
                    If a payment method is canceled or if payment is not received, we reserve the right to stop working
                    with you and to decline any future inquiries or communications. By agreeing to these terms, you
                    acknowledge and accept this policy.
                    For further details, please refer to our Returns Policy.
                </p>

                <h3>SECTION 8 - OPTIONAL TOOLS OR DEVICES</h3>
                <p>
                    We may offer you access to third-party tools or devices, which we do not monitor, control, or
                    provide any input on. You acknowledge and agree that these tools or devices are provided on an “as
                    is” and “as available” basis without any warranties, representations, or conditions of any kind, and
                    without any endorsement. We assume no liability whatsoever arising from or related to your use of
                    these optional third-party tools or devices.
                    Your use of any optional tools or devices offered through our site is entirely at your own risk and
                    discretion. It is your responsibility to ensure that you are familiar with and accept the terms on
                    which these tools or devices are provided by the relevant third-party provider(s).
                    In the future, we may also introduce new services, features, tools, or devices through the website.
                    Any such additions will be subject to these Terms of Service.
                </p>

                <h3>SECTION 9 - THIRD-PARTY LINKS & SERVICES & RESPONSIBILITIES</h3>
                <p>
                    If the customer decides to opt out of a subscription with Smart Bird Solution, Smart Bird Solution
                    will not provide access to its managed AWS domain services for external access. Instead, the
                    customer may use third-party services, such as DuckDNS or other DNS providers, to manage public
                    access to their system.
                    Third-Party Services: In the event the customer uses third-party services for external public
                    access, including DNS services like DuckDNS, the customer acknowledges that Smart Bird Solution will
                    not be responsible for the reliability, security, or performance of those services.
                    Customer Responsibility: The customer agrees that by opting to use third-party DNS services, they
                    assume full responsibility for managing, maintaining, and securing those services. Smart Bird
                    Solution is not liable for any issues, including but not limited to data breaches, loss of access,
                    or service outages, that may arise from the use of third-party services.
                    No AWS Domain Provision: Customers who do not subscribe to a Smart Bird Solution support or
                    maintenance plan will not be provided with access to AWS domain services. Any external access to
                    their Home Assistant system will need to be configured and maintained by the customer through
                    third-party solutions.
                    By using third-party services for external public access, the customer agrees to release Smart Bird
                    Solution from any responsibility related to the setup, configuration, or ongoing support of these
                    services.
                </p>

                <h3>SECTION 10 - USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS</h3>
                <p>
                    If you submit specific materials in response to our request (such as contest entries), or if you
                    voluntarily send us creative ideas, suggestions, proposals, or other content (collectively referred
                    to as 'comments'), you agree that we have the unrestricted right to edit, copy, publish, distribute,
                    translate, or otherwise use these comments in any medium at any time. We are not obligated to (1)
                    keep any submitted comments confidential; (2) compensate you for any comments; or (3) provide any
                    response to your comments.
                    Additionally, any ideas, suggestions, or feedback you provide that we deem useful may be
                    incorporated into our products, services, or systems. By submitting such feedback, you acknowledge
                    and agree that the information is provided voluntarily and freely, and we are not obligated to
                    provide compensation or ownership recognition for its use.
                    While we may choose to monitor, edit, or remove any content we find inappropriate, offensive,
                    unlawful, defamatory, or in violation of intellectual property rights, we are under no obligation to
                    do so
                    You agree that your comments will not infringe upon any third-party rights, including copyright,
                    trademark, privacy, personality, or other proprietary rights. Furthermore, your comments must not
                    contain harmful, defamatory, obscene, or illegal material, nor should they include any viruses or
                    malware that could compromise the functionality of our service or website. Misrepresentation, such
                    as using false email addresses or pretending to be someone else, is strictly prohibited.
                    You are solely responsible for the content and accuracy of any comments you submit. We do not take
                    responsibility for comments made by you or any third party and assume no liability for any
                    consequences resulting from their publication.
                </p>

                <h3>SECTION 11 - PERSONAL INFORMATION</h3>
                <p>
                    The submission and handling of your personal information through our website and services are
                    governed by our Privacy Policy. By using our services, you consent to the collection, use, and
                    disclosure of your personal information in accordance with the terms outlined in that policy. For
                    more details on how we manage and protect your personal data, please review our Privacy Policy.
                </p>

                <h3>SECTION 12 - ERRORS, INACCURACIES, AND OMISSIONS</h3>
                <p>
                    Occasionally, there may be information on our website or within our services that includes
                    typographical errors, inaccuracies, or omissions related to service descriptions, pricing,
                    promotions, offers, or service availability. We reserve the right to correct any such errors,
                    inaccuracies, or omissions, and to modify or update information or cancel orders if any inaccuracies
                    are found, even after an order has been submitted.
                    Please note that while we provide installation and service calls, we rely on third-party providers
                    for device delivery and are not responsible for delays caused by them. Any delays in service caused
                    by third-party shipping or issues beyond our control may impact the timing of installations or
                    service calls, but we are not liable for such delays.
                    We undertake no obligation to update, modify, or clarify any information within our Service or on
                    any related website, except as required by law. No specific update or refresh date within our
                    Service or on any related website should be interpreted as indicating that all information has been
                    modified or updated.
                </p>

                <h3>SECTION 13 - PROHIBITED USES</h3>
                <p>
                    In addition to other restrictions outlined in these Terms of Service, you are strictly prohibited
                    from using our site or its content for any of the following purposes: (a) to engage in any illegal
                    activity or to encourage others to do so; (b) to solicit involvement in any unlawful or fraudulent
                    actions; (c) to breach any applicable international, federal, state, or local laws or regulations;
                    (d) to infringe upon our intellectual property rights or the rights of third parties; (e) to harass,
                    threaten, abuse, defame, or discriminate against others based on gender, sexual orientation,
                    religion, ethnicity, race, age, national origin, or disability; (f) to submit or share false or
                    deceptive information; (g) to upload or transmit any malicious software or code that could disrupt
                    the functionality of the Service, our website, or other websites; (h) to gather or misuse the
                    personal data of others; (i) to engage in spamming, phishing, or any other unsolicited or fraudulent
                    communications; (j) to promote any offensive, obscene, or inappropriate content; or (k) to attempt
                    to bypass or compromise the security measures of our Service, website, or related websites.
                    We reserve the right to immediately terminate your access to our Service or any related platforms if
                    you violate any of these prohibited uses.
                </p>

                <h3>SECTION 14 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h3>
                <p>
                    We do not guarantee, promise, or warrant that the use of our services will be uninterrupted, timely,
                    secure, or free from errors. While we strive to ensure accuracy, we do not guarantee that the
                    results obtained from the use of our services will always be precise or reliable.
                    You acknowledge and agree that we may, at our discretion, temporarily suspend or permanently
                    discontinue the service at any time, without prior notice to you. Your use of our services is
                    entirely at your own risk. All services and products provided through the platform are offered on an
                    "as-is" and "as-available" basis, without any guarantees, representations, or warranties—whether
                    express or implied—including but not limited to implied warranties of merchantability, fitness for a
                    specific purpose, durability, or non-infringement.
                    Under no circumstances shall Smart Bird Solution, our affiliates, directors, employees, contractors,
                    agents, suppliers, or service providers be held liable for any loss, injury, claim, or any indirect,
                    incidental, punitive, special, or consequential damages of any kind. This includes, but is not
                    limited to, lost profits, lost data, loss of savings, or any similar damages—whether based on
                    contract, tort (including negligence), strict liability, or otherwise—arising from your use or
                    inability to use our services or any product purchased through our platform. This also applies to
                    any other claim related to your use of our services or products, including but not limited to,
                    errors or omissions in any content, or any loss or damage incurred due to the use of services,
                    products, or any content shared, transmitted, or made available through the service.
                    Since some jurisdictions do not allow the exclusion or limitation of liability for consequential or
                    incidental damages, our liability in such areas will be limited to the fullest extent permitted by
                    law.
                </p>

                <h3>SECTION 15 - INDEMNIFICATION</h3>
                <p>
                    You agree to indemnify, defend, and hold harmless Smart Bird Solution, along with our parent
                    company, affiliates, partners, officers, directors, agents, contractors, licensors, service
                    providers, subcontractors, suppliers, employees, and interns, from any claim, demand, or legal
                    action, including reasonable attorneys' fees, initiated by any third-party as a result of or related
                    to your violation of these Terms of Service, any referenced documents, or your breach of any law or
                    infringement on the rights of any third party.
                </p>

                <h3>SECTION 16 - SEVERABILITY</h3>
                <p>
                    If any part of these Terms of Service is found to be illegal, invalid, or unenforceable by a court
                    or governing authority, that portion will still be enforced to the maximum extent allowed by
                    applicable law. The unenforceable section will be considered separate from the rest of these Terms,
                    and this will not affect the validity or enforceability of the remaining provisions, which will
                    continue to be in full force and effect.
                </p>
                <h3>SECTION 17 - TERMINATION</h3>
                <p>
                    All responsibilities and obligations that either party has accrued prior to termination will
                    continue to apply after the termination of this agreement.
                    These Terms of Service will remain in effect unless terminated by either you or us. You may end
                    these Terms of Service at any point by informing us that you no longer wish to use our services, or
                    by discontinuing use of our site.
                    If, in our sole discretion, we determine that you have violated any part of these Terms, or we have
                    reason to believe that you have, we reserve the right to terminate this agreement without prior
                    notice. Upon termination, you will still be responsible for any outstanding payments up to and
                    including the date of termination. Additionally, we may block your access to our services (or any
                    part thereof) as a result.
                </p>
                <h3>SECTION 18 - ENTIRE AGREEMENT</h3>
                <p>
                    Our decision not to enforce or exercise any right or provision of these Terms of Service does not
                    mean we are waiving that right or provision in the future.
                    These Terms of Service, along with any policies or guidelines that we post on this site or provide
                    in relation to the Service, represent the complete agreement and understanding between you and us.
                    They govern your use of the Service and override any previous agreements, discussions, or proposals,
                    whether spoken or written, between you and us (including any earlier versions of these Terms of
                    Service).
                    Any uncertainties in interpreting these Terms of Service will not be interpreted unfavorably toward
                    the party that drafted them.
                </p>

                <h3>SECTION 19 - GOVERNING LAW</h3>
                <p>
                    These Terms of Service and any separate agreements whereby we provide you Services shall be governed
                    by and construed in accordance with the laws of SmartBirdSolution.com.
                </p>

                <h3>SECTION 20 - CHANGES TO TERMS OF SERVICE</h3>
                <p>
                    The most up-to-date version of these Terms of Service can always be found on this page for your
                    reference.

                    We retain the right, at our sole discretion, to modify, update, or replace any portion of these
                    Terms by posting the revised Terms on our website. It is your responsibility to review this page
                    periodically for any changes. Your continued use of or access to our website or the Service after
                    any changes are posted indicates your acceptance of the revised Terms of Service.
                </p>


                <h3>SECTION 21 - Smart Home Product Safety and Liability Policy</h3>
                <p>
                    At Smart Bird Solution, we prioritize the safety, functionality, and reliability of our Smart Home
                    products. However, it is important that users understand that any risks associated with the use of
                    our products are the responsibility of the user when used improperly or in ways not intended by the
                    design and usage instructions.

                    Safe Use & Responsibilities:

                    Proper Use: The user is responsible for ensuring that the Smart Home product is installed, set up,
                    and used according to the instructions and recommendations provided in the product manual and any
                    official documentation supplied by Smart Bird Solution.

                    User Responsibility: By using this product, the user acknowledges that it is their responsibility to
                    ensure that the product is used in a manner that does not compromise personal safety, health, or
                    property.

                    Health and Safety Concerns: While Smart Bird Solution endeavors to create safe and high-quality
                    products, we are not liable for any adverse health effects or injuries that may arise from the use
                    of this product. Users should seek professional advice if they have any concerns regarding potential
                    health impacts, including but not limited to electromagnetic fields, electrical interference, or
                    device malfunctions.

                    Limitations of Liability: Smart Bird Solution shall not be held liable for any damage to health,
                    injury, or other harm caused by the failure to follow proper usage instructions or for accidents
                    resulting from improper installation, maintenance, or use of the product.

                    Product Maintenance: The user is responsible for regular maintenance and care of the product,
                    including software updates and physical inspections as per the guidelines provided. Failure to
                    maintain the product properly may lead to malfunctions or safety risks.

                    By using this product, the user agrees to accept full responsibility for any consequences resulting
                    from misuse or improper handling.
                </p>

                <h3>SECTION 22 - CONTACT INFORMATION</h3>
                <p>
                    Questions about the Terms of Service should be sent to us at SmartBirdService@gmail.com.
                </p>
            </section>
            <BackHomeBtn/>
            <Footer/>
        </div>
    );
};

export default TermsOfService;
